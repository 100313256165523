<header>
  <div class="container-fluid">
    <div class="content-header">
      <div class="container d-flex">
        <div class="w-50 p-2">
          <a class="logo" href="/index">NC Manager</a>
        </div>
        <div class=" w-50 d-flex align-items-center flex-row-reverse">
          <div class="logout"    matTooltip="Logout" (click)="logOut()">
            <i  class="fa fa-arrow-right"></i>
          </div>
          <div class="nameuser" matTooltip="{{role}}">
            {{firstname +" " + lastname}}
          </div>

          </div>
      </div>
    </div>
  </div>
</header>
