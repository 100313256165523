import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginDto } from '../models/LoginDto';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { }


  //prefix: string = 'https://localhost:7185/api/Account';
  prefix: string = environment.serverUrl + '/api/Account';;

  public token() {
    return new HttpHeaders()
      .set('Authorization', "Bearer " + localStorage.getItem("JWT"));
  }

  public Login(user: LoginDto) {
    return this.httpClient.post<any>(this.prefix + '/Login',  user, { responseType: 'json' } );
  }

  public GetAllAccount() {
    return this.httpClient.get<boolean>(this.prefix + '/Team', { headers: this.token() });
  }
  public getAllLeaders() {
    return this.httpClient.get<boolean>(this.prefix + '/Leaders', { headers: this.token() });
  }
}

