import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { dataExcel, insertNonconf, nonConf, nonConfDto } from '../models/nonConfDto';

@Injectable({
  providedIn: 'root'
})
export class nonconformityService {

  constructor(private httpClient: HttpClient) { }


  //prefix: string = 'https://localhost:7185/api/Nonconformity';
  prefix: string = environment.serverUrl + '/api/Nonconformity';

  public token() {
    return new HttpHeaders()
      .set('Authorization', "Bearer " + localStorage.getItem("JWT"));
  }

  public getALL(role: any) {
    return this.httpClient.get<any>(this.prefix + '/Detail?role='+role, { headers: this.token() });
  }

  public insertNonconf(param: insertNonconf) {
    return this.httpClient.post<nonConf>(this.prefix + '/Insert', param ,{ headers: this.token() });
  }

  public updateNonconf(param: insertNonconf){
    return this.httpClient.post<nonConf>(this.prefix + '/Update', param ,{headers: this.token() });
  }

  public deleteNonconf(id: number){
    return this.httpClient.get<nonConf>(this.prefix + '/Delete?id='+id ,{headers: this.token() });
  }

  public detailexcel(data: dataExcel){
    return this.httpClient.post(this.prefix + '/detailExcel', data ,{headers: this.token(),  responseType: 'text',});
  }
  public allNCexcel(data: dataExcel){
    return this.httpClient.post(this.prefix + '/allNCExcel', data ,{headers: this.token(), responseType: 'text',});
  }


}

