<app-header></app-header>
<div class="container my-5">


<div class="row">
  <div class="col-10 d-flex align-items-center">
    <a href="/index"> <i class="fa fa-arrow-left" style="font-size: 30px;"></i></a>
    <h2 class="title-section m-0">Modifica Non conformità</h2>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <button  class="btn-add" (click)="getPDF()"><i class="fa fa-download" style="margin-right: 5px;"></i> Scarica</button>
  </div>
</div>
<div class="row mt-3" id="pdfPage" *ngIf="dataToEdit">
  <div class="col-12">
    <form #f="ngForm" (ngSubmit)="onsubmit(f)" novalidate>
      <div class="first-section">
        <div class="col-12">
          <h4 class="bolder text-center mb-4">{{dataToEdit.id}}</h4>
        </div>
        <div class="row pt-4">
          <!-- side 1 -->
          <div class="col-7">
            <div class="row">
              <!-- tipologia -->
              <div class="col-12">
                <label class="bolder">Tipologia di problema</label>
                <div class="position-relative">
                  <input type="text" name="tipologiaProb" placeholder="Tipologia" class="form-control" [ngModel] ="dataToEdit.typology" ngModel required/>
                </div>
              </div>
              <!-- cause into est -->
              <div class="col-12 mt-5">
                <label>Cause:</label>
                <div class="row">
                  <div class="position-relative">
                    <input type="checkbox" name="checkfornitore"   [(ngModel)]="isFornitoreChecked" (change)="onCheckboxFornitoreChanged()" ngModel required="{{!isFornitoreChecked && !isSettorenoChecked}}"/>
                    <label style="margin-left:10px;" for="horns">Fornitore (indicare il nome)</label>
                    <input *ngIf="isFornitoreChecked" type="text" name="nomeCause" placeholder="Nome" class="form-control" [(ngModel)]="dataToEdit.reasonReportName" ngModel/>
                    <input *ngIf="isFornitoreChecked" type="text" name="motivo" placeholder="Motivo" class="form-control mt-2" [(ngModel)]="dataToEdit.reasonReportDesc" ngModel/>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="position-relative">
                    <input type="checkbox" name="checkinteroSettore"   [(ngModel)]="isSettorenoChecked" (change)="onCheckboxInteSettoreChanged()" ngModel required="{{!isFornitoreChecked && !isSettorenoChecked}}"/>
                    <label style="margin-left:10px;" for="horns">Interna (indicare il Settore)</label>
                    <mat-form-field *ngIf="isSettorenoChecked" class="autocomplete mt-2" appearance="fill">
                      <mat-label>Nome</mat-label>
                      <mat-select [(ngModel)]="dataToEdit.reasonReportName" [formControl]="internoSetbyAllUsersform">
                        <mat-select-trigger >
                          {{internoSetbyAllUsersform.value}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let nome of allUsers" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <input *ngIf="isSettorenoChecked" type="text" name="motivo" placeholder="Motivo" class="form-control mt-2" [(ngModel)]="dataToEdit.reasonReportDesc" ngModel/>
                  </div>
                </div>
                <div *ngIf="f.submitted && f.controls['checkfornitore'].value ===false && f.controls['checkinteroSettore'].value ===false" class="alert mt-3 alert-danger">
                  Deve essere selezionato un campo
                </div>
              </div>
              <!-- intervento di recuper -->
              <div class="col-12 mt-5">
                <label class="bolder">Intervento di recupero</label>
                <div class="position-relative">
                  <textarea name="interventoRecupero" id="" rows="7" cols="33" style="width:100%;" [(ngModel)]="dataToEdit.descIntervention" ngModel></textarea>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <label>Eseguito da:</label>
                    <div class="position-relative">
                      <mat-form-field class="autocomplete" appearance="fill">
                        <mat-label>Nome</mat-label>
                        <mat-select [(ngModel)]="dataToEdit.interventionBy" [formControl]="eseguitotoDa">
                          <mat-select-trigger >
                            {{eseguitotoDa.value}}
                          </mat-select-trigger>
                          <mat-option *ngFor="let nome of allUsers" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-6">
                    <label>In data:</label>
                    <div class="position-relative">
                      <input type="date" name="dataIntervento"  class="form-control" [ngModel] ="dataToEdit.dateIntervention | date:'yyyy-MM-dd'" ngModel/>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Azione Correttiva -->
              <div class="col-12 mt-5">
                <label class="bolder">Azione Correttiva</label>
                <div class="position-relative">
                  <textarea name="azioneCorrettiva" id="" rows="11" cols="33" style="width:100%;" [(ngModel)]="dataToEdit.actionIntervention" ngModel></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- side 2-->
          <div class="col-4 offset-1">
            <div class="row">
              <!-- Data NC from -->
              <div class="col-12">
                <label for="date" class="bolder">Data da</label>
                <input  type="datetime-local" [max]="dataToEdit.dateNonconformityTo"  name="date" placeholder="Data da" class="form-control" [(ngModel)] ="dataToEdit.dateNonconformity" >
              </div>
               <!-- Data NC to -->
               <div class="col-12 mt-3">
                <label for="date" class="bolder">Data a</label>
                <input  type="datetime-local" [min]="dataToEdit.dateNonconformity"  name="dateTo" placeholder="Data a" class="form-control" [(ngModel)] ="dataToEdit.dateNonconformityTo" >
              </div>
              <!-- Segnalato da-->
              <div class="col-12 mt-5">
                <label>Segnalato da:</label>
                <div class="row">
                  <div class="position-relative">
                    <input type="checkbox" name="checkcliente" [(ngModel)]="isClientChecked" (change)="onCheckboxClientChanged()" required="{{!isInternoChecked && !isClientChecked}}"/>
                    <label style="margin-left:10px;" for="horns">Cliente</label>
                    <input *ngIf="isClientChecked" type="text" name="segnalatoDa" placeholder="Nome" class="form-control" [(ngModel)]="dataToEdit.reportersName"  ngModel/>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="position-relative">
                    <input type="checkbox" name="checkinterno" [(ngModel)]="isInternoChecked"   (change)="onCheckboxRileInteChanged()" required="{{!isInternoChecked && !isClientChecked}}"/>
                    <label style="margin-left:10px;" for="horns">Rilevata internamente</label>
                    <mat-form-field *ngIf="isInternoChecked" class="autocomplete mt-2" appearance="fill">
                      <mat-label>Nome</mat-label>
                      <mat-select [(ngModel)]="dataToEdit.reportersName" [formControl]="repotedbyAllUsersform">
                        <mat-select-trigger>
                          {{dataToEdit.reportersName}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let nome of allUsers" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="f.submitted && f.controls['checkcliente'].value ===false && f.controls['checkinterno'].value ===false" class="alert mt-3 alert-danger">
                  Deve essere selezionato un campo
                </div>
              </div>
               <!-- Costo intervento-->
               <div class="col-12 mt-5">
                  <div class="row">
                    <div class="col-12 text-center">
                      <label class="bolder">Costo Intervento</label>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <label>Manodopera:</label>
                    <div class="position-relative">
                      <input type="number" name="manodoperaIntervento" [(ngModel)]="dataToEdit.interventionLabourCost" (ngModelChange)="updateResultIntervention()" class="form-control"  />
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Perdità aziendale:</label>
                    <div class="position-relative">
                      <input type="number" name="perditàAziendaleIntervento" [(ngModel)]="dataToEdit.interventionCompanyLosses" (ngModelChange)="updateResultIntervention()" class="form-control"  />
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Costo Totale:</label>
                    <div class="position-relative">
                      <input type="number" name="costoTotIntervento"  [(ngModel)]="dataToEdit.interventionLabourCost+dataToEdit.interventionCompanyLosses" readonly class="form-control"  />
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>
               </div>
               <!-- Costo Azione Correttiva-->
               <div class="col-12 mt-5">
                  <div class="row">
                    <div class="col-12 text-center">
                      <label class="bolder">Costo Azione Correttiva</label>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Manodopera:</label>
                    <div class="position-relative">
                      <input type="number" name="manodoperaAzCor" [(ngModel)]="dataToEdit.correctiveLabourCost" (ngModelChange)="updateResultCorrective()" class="form-control"/>
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Materiale:</label>
                    <div class="position-relative">
                      <input type="number" name="materiale"  [(ngModel)]="dataToEdit.correctiveMaterial" (ngModelChange)="updateResultCorrective()" class="form-control"/>
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Costo Totale:</label>
                    <div class="position-relative">
                      <input type="number" name="costoTotAzCor"   [(ngModel)]="dataToEdit.correctiveLabourCost + dataToEdit.correctiveMaterial" readonly class="form-control"/>
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                </div>
            </div>
          </div>
        </div>


      </div>

      <h2 class="title-section mt-4" *ngIf="IsAdmin || IsSuperAdmin" [ngStyle]="{'padding':onPdf === true ? '0px 65px' : '0px'}">Validazione Responsabile</h2>
      <div class="second-section mt-3" *ngIf="IsAdmin || IsSuperAdmin">
        <div class="row">
           <!-- side 1 -->
          <div class="col-7">
            <!-- RV responsabile -->
            <div class="col-12">
              <label for="horns" class="bolder">Responsabile</label>
              <div class="position-relative">
                <input type="text" name="RVnomeresponsabile" placeholder="Nome" class="form-control" [ngModel] ="dataToEdit.rvName" ngModel/>
              </div>
            </div>
            <!-- Esito dell'azione Correttiva -->
            <div class="col-12 mt-5">
              <label clsa="m-0">Esito dell'azione Correttiva:</label>

              <div class="row position-relative mt-3">
                <div class="col-4">
                  <div class="positive d-flex  align-items-center">
                    <input type="checkbox" name="RVEsitopositivo"   [(ngModel)]="isPositiveChecked" (change)="onCheckboxRVPositiveChanged()" required="{{!isPositiveChecked && !isNegativeChecked}}"/>
                    <label class="mx-4 mb-0" for="horns">Positivo</label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="negative align-items-center">
                    <input type="checkbox" name="RVEsitonagativo"   [(ngModel)]="isNegativeChecked" (change)="onCheckboxRVNegativeChanged()" required="{{!isPositiveChecked && !isNegativeChecked}}"/>
                    <label class="mx-4 mb-0" for="horns">Negativo</label>
                  </div>
                </div>
                <div *ngIf="f.submitted && f.controls['RVEsitopositivo'].value ===false && f.controls['RVEsitonagativo'].value ===false" class="alert alert-danger">
                  Deve essere selezionato un campo
                </div>
              </div>
            </div>
            <!-- Esito dell'azione Correttiva -->
            <div class="col-12 mt-5">
              <label>Nome di chi ha eseguito la Verifica</label>
              <div class="position-relative">
                <mat-form-field class="autocomplete" appearance="fill">
                  <mat-label>Nome</mat-label>
                  <mat-select [(ngModel)] ="dataToEdit.rvVerifiedByName" [formControl]="RVverificataDa">
                    <mat-select-trigger >
                      {{RVverificataDa.value}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let nome of allUsers" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <!-- side 2 -->
          <div class="col-4 offset-1">
            <!-- RV da eseguire entro.. -->
            <div class="col-12">
              <label for="horns" class="bolder">Da eseguire entro il</label>
              <div class="position-relative">
                <input type="date" name="RVlastDate"  class="form-control" [ngModel] ="dataToEdit.rvCompletedBefore | date:'yyyy-MM-dd'" ngModel/>
              </div>
            </div>
            <!-- data verifica -->
            <div class="col-12 mt-5">
              <label for="horns">Data Verifica</label>
              <div class=" position-relative">
                <input type="date" name="RVdataVerifica"  class="form-control" [ngModel] ="dataToEdit.rvVerifiedDate | date:'yyyy-MM-dd'" ngModel/>
              </div>
            </div>
            <!-- tipo NC -->
            <div class="col-12 mt-5">
              <label>Tipo di Non Conforrmità</label>
              <div class=" position-relative">
                <div class="row mt-3">
                  <div class="col-6">
                    <div class="position-relative d-flex align-items-center">
                      <input type="checkbox" name="tipoNCgrave" [(ngModel)]="isNCSeriousChcked" (change)="onCheckboxseriousChanged()" required="{{!isNCSeriousChcked && !isNCMinorChecked}}"/>
                      <label class="mx-3 mb-0" for="horns">N.C grave</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="position-relative d-flex align-items-center">
                      <input type="checkbox" name="tipoNCminore" [(ngModel)]="isNCMinorChecked"  (change)="onCheckboxminorChanged()"  required="{{!isNCSeriousChcked && !isNCMinorChecked}}"/>
                      <label class="mx-3 mb-0" for="horns">N.C minore</label>
                    </div>
                  </div>
                </div>

              </div>
              <div *ngIf="f.submitted && f.controls['tipoNCgrave'].value ===false && f.controls['tipoNCminore'].value ===false" class="alert mt-3 alert-danger">
                Deve essere selezionato un campo
              </div>
            </div>
          </div>
        </div>

      </div>

      <h2 class="title-section mt-4" *ngIf="IsSuperAdmin" [ngStyle]="{'padding':onPdf === true ? '0px 65px' : '0px'}">Validazione R.S.Q.</h2>
      <div class="last-section mt-3" *ngIf="IsSuperAdmin">
        <div class="row">
          <!-- side 1 -->
          <div class="col-7">
            <label for="horns" class="bolder">Non conformità chiusa?</label>
            <div class="col-12">
              <div class="row position-relative mt-3">
                <div class="col-4">
                  <div class="positive d-flex align-items-center">
                    <input type="checkbox" name="RSQchiusaSI" [(ngModel)]="isNCcloseYes" (change)="onCheckboxRSQisNCcloseYesChanged()" required="{{!isNCcloseYes && !isNCcloseNo}}"/>
                    <label class="mx-4 mb-0" for="horns">Si</label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="negative d-flex align-items-center">
                    <input type="checkbox" name="RSQchiusaNO" [(ngModel)]="isNCcloseNo"  (change)="onCheckboxRSQisNCcloseNoChanged()" required="{{!isNCcloseYes && !isNCcloseNo}}"/>
                    <label class="mx-4 mb-0" for="horns">No</label>
                  </div>
                </div>
              </div>
              <div *ngIf="f.submitted && f.controls['RSQchiusaSI'].value ===false && f.controls['RSQchiusaNO'].value ===false" class="alert mt-3 alert-danger">
                Deve essere selezionato un campo
              </div>
            </div>
            <div class="col-12 mt-5">
              <label>Firma:</label>
              <div class="position-relative">
                <input type="text" name="RSQnomeResponsabile" placeholder="Nome e Cognome" class="form-control" [ngModel] ="dataToEdit.rsqResponsibleName" ngModel/>
              </div>
            </div>
          </div>
          <div class="col-4 offset-1">
            <div class="col-12">
              <label>Data chiusura:</label>
              <div class="position-relative">
                <input type="date" name="RSQdataChiusura" class="form-control" [ngModel] ="dataToEdit.rsqClosingDate | date:'yyyy-MM-dd'" ngModel/>
              </div>
            </div>
          </div>
        </div>




      </div>


      <div *ngIf="!onPdf" class="row mt-4 d-flex justify-content-center">

        <button type="submit" class="btn btn-main">Invia</button>
      </div>


    </form>
  </div>
</div>

</div>

<app-footer></app-footer>
