import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { CheckboxControlValueAccessor, FormControl, NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { insertNonconf, nonConfDto } from 'src/app/models/nonConfDto';
import {  reportedbyClientorInternal, isFornitoreOrSettoreint, isRvResultCa, typeNC, isRsqIsRelevant } from 'src/app/models/allcheckbox';
import { LoginService } from 'src/app/services/login.service';
import { nonconformityService } from 'src/app/services/nonconformity.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-edit-nc',
  templateUrl: './edit-nc.component.html',
  styleUrls: ['./edit-nc.component.css']
})
export class EditNCComponent implements OnInit {

  detailNonConf: nonConfDto[] = [];
  idclicked!: number
  dataToEdit: any

  isClientChecked = false;
  isInternoChecked = false;
  reportBy!: number;

  isFornitoreChecked = false;
  isSettorenoChecked = false;
  reasonRepBy!: number;

  /**RV */
  isPositiveChecked = false;
  isNegativeChecked = false;
  positiveOrNegative!: number;

  isNCSeriousChcked = false;
  isNCMinorChecked = false;
  seriousOrMinor!: number

  /**RSQ */
  isNCcloseYes = false
  isNCcloseNo = false
  yesOrNo!: number

  totalInterventionCost: number = 0;
  interventionLabourCost : number = 0 ;
  interventionCompanyLosses: number = 0;

  correctiveLabourCost: number = 0;
  correctiveMaterial: number = 0;
  correctiveTotalCost: number = 0;

  date = new Date();
  IsAdmin: boolean = localStorage.getItem("role") == "Admin";
  IsSuperAdmin: boolean = localStorage.getItem("role") == "SuperAdmin";



  /**allUsers */
  allUsers: any;
  repotedbyAllUsersform = new FormControl();
  internoSetbyAllUsersform = new FormControl()
  eseguitotoDa = new FormControl();
  RVverificataDa = new FormControl();

  constructor(private nonconfservice: nonconformityService, private loginService: LoginService, private el: ElementRef, private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getallAccount()

    this.route.queryParams.subscribe((params: any )=> {
      this.idclicked = params['id'];
    });
    this.getAlldata()


  }


  /**Controllo tutte le checbox */
  checkAllCheckBoxToedit(){
    if(this.dataToEdit.reportedBy == reportedbyClientorInternal.reportedbyInternal){
      this.isInternoChecked= true
      this.isClientChecked= false
      this.reportBy = reportedbyClientorInternal.reportedbyInternal
    }else{
      this.isClientChecked= true
      this.isInternoChecked= false
      this.reportBy = reportedbyClientorInternal.reportedbyClient
    }

    if(this.dataToEdit.reasonReportBy == isFornitoreOrSettoreint.reasonRepBySettoreInt){
      this.isSettorenoChecked= true
      this.isFornitoreChecked = false
      this.reasonRepBy = isFornitoreOrSettoreint.reasonRepBySettoreInt;
    }else{
      this.isFornitoreChecked= true
      this.isSettorenoChecked= false
      this.reasonRepBy = isFornitoreOrSettoreint.reasonRepByFornitore;
    }

    /**poi ci sarà il controllo s'è admin */
    if(this.dataToEdit.rvResultCa == isRvResultCa.rvResultCaPositive){
      this.isPositiveChecked=true
      this.isNegativeChecked=false
      this.positiveOrNegative = isRvResultCa.rvResultCaPositive
    }else if(this.dataToEdit.rvResultCa == isRvResultCa.rvResultCaNegative){
      this.isPositiveChecked=false
      this.isNegativeChecked=true
      this.positiveOrNegative = isRvResultCa.rvResultCaNegative
    }
    if(this.dataToEdit.rvTypeNonConformity == typeNC.minore){
      this.isNCMinorChecked=true
      this.isNCSeriousChcked=false
      this.seriousOrMinor= typeNC.minore;
    }else if(this.dataToEdit.rvTypeNonConformity == typeNC.grave){
      this.isNCMinorChecked=false
      this.isNCSeriousChcked=true
      this.seriousOrMinor= typeNC.grave;
    }
    /**poi ci sarà il controllo s'è superadmin */
    if(this.dataToEdit.rsqIsRelevant == isRsqIsRelevant.rsqIsRelevantNo){
      this.isNCcloseYes=false
      this.isNCcloseNo=true
      this.yesOrNo = isRsqIsRelevant.rsqIsRelevantNo;
    }else if(this.dataToEdit.rsqIsRelevant == isRsqIsRelevant.rsqIsRelevantYes){
      this.isNCcloseYes=true
      this.isNCcloseNo=false
      this.yesOrNo = isRsqIsRelevant.rsqIsRelevantYes;
    }
  }

  onCheckboxClientChanged() {
    if (this.isClientChecked) {
      this.isInternoChecked = false;
      this.reportBy = reportedbyClientorInternal.reportedbyClient
    }
  }
  onCheckboxRileInteChanged() {
    if (this.isInternoChecked) {
      this.isClientChecked = false;
      this.reportBy = reportedbyClientorInternal.reportedbyInternal
    }
  }
  onCheckboxFornitoreChanged(){
    if (this.isFornitoreChecked) {
      this.isSettorenoChecked = false;
      this.reasonRepBy = isFornitoreOrSettoreint.reasonRepByFornitore;;
    }
  }
  onCheckboxInteSettoreChanged(){
    if (this.isSettorenoChecked) {
      this.isFornitoreChecked = false;
      this.reasonRepBy = isFornitoreOrSettoreint.reasonRepBySettoreInt;;
    }
  }

  /**admin RV*/
  onCheckboxRVPositiveChanged() {
    if (this.isPositiveChecked) {
      this.isNegativeChecked = false;
      this.positiveOrNegative = isRvResultCa.rvResultCaPositive
    }
  }
  onCheckboxRVNegativeChanged() {
    if (this.isNegativeChecked) {
      this.isPositiveChecked = false;
      this.positiveOrNegative = isRvResultCa.rvResultCaNegative
    }
  }
  onCheckboxseriousChanged() {
    if (this.isNCSeriousChcked) {
      this.isNCMinorChecked = false;
      this.seriousOrMinor = typeNC.grave
    }
  }
  onCheckboxminorChanged() {
    if (this.isNCMinorChecked) {
      this.isNCSeriousChcked = false;
      this.seriousOrMinor = typeNC.minore
    }
  }
    /**superAdmin RSQ */
  onCheckboxRSQisNCcloseYesChanged() {
    if (this.isNCcloseYes) {
      this.isNCcloseNo = false;
      this.yesOrNo = isRsqIsRelevant.rsqIsRelevantYes
    }
  }
  onCheckboxRSQisNCcloseNoChanged() {
    if (this.isNCcloseNo) {
      this.isNCcloseYes = false;
      this.yesOrNo = isRsqIsRelevant.rsqIsRelevantNo
    }
  }


  updateResultIntervention(): void {
    const sum = Number(this.dataToEdit.interventionLabourCost) + Number(this.dataToEdit.interventionCompanyLosses);
   this.dataToEdit.interventionTotalCost = sum;
  }
  updateResultCorrective(): void {
    const sum = Number(this.dataToEdit.correctiveLabourCost) + Number(this.dataToEdit.correctiveMaterial);
    this.dataToEdit.correctiveTotalCost = sum;
  }


  getAlldata(){

    var role = localStorage.getItem('role');
    this.nonconfservice.getALL(role).subscribe(result => {
          result.find((resulta: { id: number; }) => {
          if(resulta.id == this.idclicked){
            this.dataToEdit = resulta;
            this.checkAllCheckBoxToedit()
            return this.dataToEdit;
          }

        });
      },
      err => {
        console.log('HTTP Error', err)
        if (err.status == 401)
          window.open("/", "_self");
      }
    );



  }



  onsubmit(f: any){
    let param = new insertNonconf();

    param.Id = this.idclicked;
    param.dateNonconformity = f.value.date;
    param.dateNonconformityTo = f.value.dateTo;
    param.typology = f.value.tipologiaProb;

    param.ReportedBy = this.reportBy;
    if(this.reportBy == 0){
      param.reportersName = this.repotedbyAllUsersform.value }
    else{
      param.reportersName = f.value.segnalatoDa
    };

    param.reasonReportBy = this.reasonRepBy;
    if(this.reasonRepBy == 0){
      param.ReasonReportName = this.internoSetbyAllUsersform.value
    }else{
      param.ReasonReportName = f.value.nomeCause
    }
    param.ReasonReportDesc = f.value.motivo

    param.descIntervention = f.value.interventoRecupero;
    param.interventionBy = this.eseguitotoDa.value;
    param.dateIntervention = f.value.dataIntervento;
    param.interventionLabourCost = f.value.manodoperaIntervento;
    param.interventionCompanyLosses = f.value.perditàAziendaleIntervento;

    param.actionIntervention = f.value.azioneCorrettiva;
    param.correctiveLabourCost = f.value.manodoperaAzCor;
    param.correctiveMaterial = f.value.materiale;

    if(this.IsAdmin || this.IsSuperAdmin){
      param.rvName = f.value.RVnomeresponsabile;
      param.rvCompletedBefore = f.value.RVlastDate;
      param.rvResultCa = this.positiveOrNegative;
      param.rvVerifiedDate = f.value.RVdataVerifica;
      param.rvVerifiedByName = this.RVverificataDa.value;
      param.rvTypeNonConformity = this.seriousOrMinor;

    }else{
      param.rvName = this.dataToEdit.rvName;
      param.rvCompletedBefore = this.dataToEdit.rvCompletedBefore;
      param.rvResultCa = this.dataToEdit.rvResultCa;
      param.rvVerifiedDate = this.dataToEdit.rvVerifiedDate;
      param.rvVerifiedByName = this.dataToEdit.rvVerifiedByName;
      param.rvTypeNonConformity = this.dataToEdit.rvTypeNonConformity;
    }

    if(this.IsSuperAdmin){

      param.rsqResponsibleName = f.value.RSQnomeResponsabile;
      param.rsqIsRelevant = this.yesOrNo;
      param.rsqClosingDate = f.value.RSQdataChiusura;
    }else{
      param.rsqResponsibleName = this.dataToEdit.rsqResponsibleName;
      param.rsqIsRelevant = this.dataToEdit.rsqIsRelevant;
      param.rsqClosingDate = this.dataToEdit.rsqClosingDate;
    }

    if(f.status =='VALID'){
      this.nonconfservice.updateNonconf(param).subscribe(
        result => {
          if (result)
         location.href="/index"
          else
            alert('errore')
          },
        err => {
          console.log('HTTP Error', err)
          if (err.status == 401)
            window.open("/", "_self");
        }
      );
    }else{
      this.scrollToFirstInvalidControl()
    }
  }


  getallAccount(){
    this.loginService.GetAllAccount().subscribe(result=>{
      this.allUsers = result;
    })
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    firstInvalidControl.focus(); //without smooth behavior
  }


  onPdf: boolean = false
  getPDF(){
    this.onPdf = true;
    setTimeout(() => {
      this.downloadAsPDF();
      this.onPdf = false;
      this.cdr.detectChanges();
    });

  }
  downloadAsPDF() {
    const pdfDiv = document.getElementById('pdfPage')!;

    html2canvas(pdfDiv).then(canvas => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const doc = new jsPDF('p', 'mm', [imgWidth, imgHeight]);
      doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);
      doc.save("Non_conformità_" + this.dataToEdit.id + '.pdf');
    });

    this.onPdf = true;
  }




}

