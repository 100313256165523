import { Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatGridAvatarCssMatStyler } from '@angular/material/grid-list';
import { Router } from '@angular/router';

import { insertNonconf } from 'src/app/models/nonConfDto';
import {  reportedbyClientorInternal, isFornitoreOrSettoreint, isRvResultCa, typeNC, isRsqIsRelevant } from 'src/app/models/allcheckbox';
import { LoginService } from 'src/app/services/login.service';
import { nonconformityService } from 'src/app/services/nonconformity.service';

@Component({
  selector: 'app-add-new-nc',
  templateUrl: './add-new-nc.component.html',
  styleUrls: ['./add-new-nc.component.css']
})
export class AddNewNCComponent implements OnInit {

  isClientChecked = false;
  isInternoChecked = false;
  reportBy!: number;

  isFornitoreChecked = false;
  isSettorenoChecked = false;
  reasonRepBy!: number;

  /**RV */
  isPositiveChecked = false;
  isNegativeChecked = false;
  positiveOrNegative!: number;

  isNCSeriousChcked = false;
  isNCMinorChecked = false;
  seriousOrMinor!: number

  /**RSQ */
  isNCcloseYes = false
  isNCcloseNo = false
  yesOrNo!: number

  interventionLabourCost: number = 0 ;
  interventionCompanyLosses: number = 0;
  totalInterventionCost!: number;

  correctiveLabourCost: number = 0;
  correctiveMaterial: number = 0;
  correctiveTotalCost!: number ;

  repotedbyAllUsersform = new FormControl();
  allUsers: any;
  internoSetbyAllUsersform = new FormControl()
  eseguitotoDa = new FormControl();
  RVverificataDa = new FormControl();
  checkLeader: boolean = true
  leaders: any
  nameLeader  = new FormControl();
  date = new Date();
  dateTo = new Date();
  IsAdmin: boolean = localStorage.getItem("role") == "Admin";
  IsSuperAdmin: boolean = localStorage.getItem("role") == "SuperAdmin";
  constructor(private nonconfservice: nonconformityService,  private el: ElementRef, private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {
    this.getallAccount()
    this.getallLeaders()
  }




  onCheckboxClientChanged() {
    if (this.isClientChecked) {
      this.isInternoChecked = false;
      this.reportBy = reportedbyClientorInternal.reportedbyClient
    }
  }
  onCheckboxRileInteChanged() {
    if (this.isInternoChecked) {
      this.isClientChecked = false;
      this.reportBy = reportedbyClientorInternal.reportedbyInternal
    }
  }
  onCheckboxFornitoreChanged(){
    if (this.isFornitoreChecked) {
      this.isSettorenoChecked = false;
      this.reasonRepBy = isFornitoreOrSettoreint.reasonRepByFornitore
    }
  }
  onCheckboxInteSettoreChanged(){
    if (this.isSettorenoChecked) {
      this.isFornitoreChecked = false;
      this.reasonRepBy = isFornitoreOrSettoreint.reasonRepBySettoreInt
    }
  }


  updateResultIntervention(): void {
    const sum = Number(this.interventionLabourCost) + Number(this.interventionCompanyLosses);
    this.totalInterventionCost = sum;
  }
  updateResultCorrective(): void {
    const sum = Number(this.correctiveLabourCost) + Number(this.correctiveMaterial);
    this.correctiveTotalCost = sum;
  }

  /**admin RV*/
  onCheckboxRVPositiveChanged() {
    if (this.isPositiveChecked) {
      this.isNegativeChecked = false;
      this.positiveOrNegative = isRvResultCa.rvResultCaPositive
    }
  }
  onCheckboxRVNegativeChanged() {
    if (this.isNegativeChecked) {
      this.isPositiveChecked = false;
      this.positiveOrNegative = isRvResultCa.rvResultCaNegative
    }
  }


  onCheckboxseriousChanged() {
    if (this.isNCSeriousChcked) {
      this.isNCMinorChecked = false;
      this.seriousOrMinor = typeNC.grave
    }
  }
  onCheckboxminorChanged() {
    if (this.isNCMinorChecked) {
      this.isNCSeriousChcked = false;
      this.seriousOrMinor = typeNC.minore
    }
  }
   /**superAdmin RSQ */
  onCheckboxRSQisNCcloseYesChanged() {
    if (this.isNCcloseYes) {
      this.isNCcloseNo = false;
      this.yesOrNo = isRsqIsRelevant.rsqIsRelevantYes
    }
  }
  onCheckboxRSQisNCcloseNoChanged() {
    if (this.isNCcloseNo) {
      this.isNCcloseYes = false;
      this.yesOrNo = isRsqIsRelevant.rsqIsRelevantNo
    }
  }



  onsubmit(f: any){
    console.log(f)
    let param = new insertNonconf();
    param.dateNonconformity = f.value.date;
    param.dateNonconformityTo = f.value.dateTo;
    param.typology = f.value.tipologiaProb;

    param.ReportedBy = this.reportBy;
    if(this.reportBy == reportedbyClientorInternal.reportedbyInternal){
      param.reportersName = this.repotedbyAllUsersform.value }
    else{
      param.reportersName = f.value.segnalatoDa
    };


    param.reasonReportBy = this.reasonRepBy;
    if(this.reasonRepBy == isFornitoreOrSettoreint.reasonRepBySettoreInt){
      param.ReasonReportName = this.internoSetbyAllUsersform.value
    }else{
      param.ReasonReportName = f.value.nomeCause
    }
    param.ReasonReportDesc = f.value.motivo

    param.descIntervention = f.value.interventoRecupero;
    param.interventionBy = this.eseguitotoDa.value;
    param.dateIntervention = f.value.dataIntervento;
    param.interventionLabourCost = f.value.manodoperaIntervento;
    param.interventionCompanyLosses = f.value.perditàAziendaleIntervento;

    param.actionIntervention = f.value.azioneCorrettiva;
    param.correctiveLabourCost = f.value.manodoperaAzCor;
    param.correctiveMaterial = f.value.materiale;

    /**email to leader area */
    var filteredLeaders  = this.leaders.filter((email:any) => email.fullName == this.nameLeader.value)
    var email;
    if(filteredLeaders.length > 0){
      email = filteredLeaders[0].email
    }else{
      this.checkLeader = false
    }
    param.emailLeader = email;

    param.rvResultCa= 3
    param.rvTypeNonConformity = 3
    if(this.IsSuperAdmin || this.IsAdmin){
      param.rvName = f.value.RVnomeresponsabile;
      param.rvCompletedBefore = f.value.RVlastDate;
      param.rvResultCa= this.positiveOrNegative;
      param.rvVerifiedDate= f.value.RVdataVerifica;
      param.rvVerifiedByName = this.RVverificataDa.value;
      param.rvTypeNonConformity = this.seriousOrMinor;

    }
    if(this.IsSuperAdmin){
      param.rsqResponsibleName = f.value.RSQnomeResponsabile;
      param.rsqIsRelevant = this.yesOrNo;
      param.rsqClosingDate = f.value.RSQdataChiusura
    }




    if(f.status =='VALID' && this.checkLeader){
      this.nonconfservice.insertNonconf(param).subscribe(
        result => {
          if (result) {
            location.href="/index"
          } else {
            alert('errore')
          }
        },
        err => {
          console.log('HTTP Error', err)
          if (err.status == 401)
            window.open("/", "_self");
        }


      );

    }else{
      this.scrollToFirstInvalidControl();
    }


  }


  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    firstInvalidControl.focus(); //without smooth behavior
  }





  getallAccount() {
    this.loginService.GetAllAccount().subscribe(
      res => this.allUsers = res,
      err => {
        console.log('HTTP Error', err)
        if (err.status == 401)
          window.open("/", "_self");
      }
    )
  }

  getallLeaders() {
    this.loginService.getAllLeaders().subscribe((res) =>{
      console.log(res, 'res')
      this.leaders = res
    })
  }

}
