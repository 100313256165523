<div class="container-fluid bg-blue masthead">
  <div class="row h-100">
    <div class="col-md-4 col-form">
      <div class="row h-100 p-4 align-items-center">
        <div class="col-12">
          <h2 class="logo text-center pb-4">NC Manager</h2>
          <form #f="ngForm" (ngSubmit)="onsubmit(f)" novalidate>
            <div class="mb-5">
              <input type="text" name="username" placeholder="Username" class="form-control" ngModel #first="ngModel" required>
            </div>
            <div class="mb-5">
              <div class="position-relative">
                <input  ngModel [type]="showPass ? 'text' : 'password'" name="password" placeholder="Password" class="form-control" ngModel required style="position: relative;"/>
                <i (click)="showPass = !showPass" class="suffix fa" [ngClass]="showPass ? 'fa-eye-slash' : 'fa-regular fa-eye'" style="font-size: 1.2rem;"></i>
              </div>
            </div>
            <button type="submit" class="btn btn-main w-100">Login</button>
          </form>
          <p class=" mt-4 mb-0 text-center" *ngIf="errorLogin">username e/o password non corretti.</p>
          <p class=" mt-4 mb-0 text-center" *ngIf="errorForm">Compilare tutti i campi.</p>
          <p class="mt-5 mb-0 text-center">
            Powered by <a href="https://reputationmanager.it" target="_blank">Reputation Manager</a>
          </p>
        </div>
      </div>
    </div>


  </div>
</div>
