<app-header></app-header>
<div class="container my-5">
  <div class="row">
    <div class="col-12">
      <h2 class="title-section">Registra una Non Conformità</h2>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <form #f="ngForm" (ngSubmit)="onsubmit(f)" novalidate>
        <div class="first-section">

          <div class="row">
            <!-- side 1 -->
            <div class="col-7">
              <div class="row">
                <!-- tipologia -->
                <div class="col-12">
                  <label class="bolder">Tipologia di problema</label>
                  <div class="position-relative">
                    <input type="text" name="tipologiaProb" placeholder="Tipologia" class="form-control"  ngModel required/>
                    <div *ngIf="f.submitted && f.controls['tipologiaProb'].status === 'INVALID'" class="alert mt-3 alert-danger">
                      La Tipologia è obbligatoria
                    </div>
                  </div>
                </div>
                <!-- cause into est -->
                <div class="col-12 mt-5">
                  <label>Cause:</label>
                  <div class="row">
                    <div class="position-relative">
                      <input type="checkbox" name="checkfornitore"   [(ngModel)]="isFornitoreChecked" (change)="onCheckboxFornitoreChanged()" required="{{!isFornitoreChecked && !isSettorenoChecked}}"/>
                      <label style="margin-left:10px;" for="horns">Fornitore (indicare il nome)</label>
                      <input *ngIf="isFornitoreChecked" type="text" name="nomeCause" placeholder="Nome" class="form-control"  ngModel/>
                      <input *ngIf="isFornitoreChecked" type="text" name="motivo" placeholder="Motivo" class="form-control mt-2" ngModel/>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="position-relative">
                      <input type="checkbox" name="checkinteroSettore"   [(ngModel)]="isSettorenoChecked" (change)="onCheckboxInteSettoreChanged()" required="{{!isFornitoreChecked && !isSettorenoChecked}}"/>
                      <label style="margin-left:10px;" for="horns">Interna (indicare il Settore)</label>
                      <mat-form-field *ngIf="isSettorenoChecked" class="autocomplete mt-2" appearance="fill">
                        <mat-label>Nome</mat-label>
                        <mat-select [formControl]="internoSetbyAllUsersform">
                          <mat-select-trigger >
                            {{internoSetbyAllUsersform.value}}
                          </mat-select-trigger>
                          <mat-option *ngFor="let nome of allUsers" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <input *ngIf="isSettorenoChecked" type="text" name="motivo" placeholder="Motivo" class="form-control mt-2" ngModel/>
                    </div>
                  </div>

                  <div *ngIf="f.submitted && f.controls['checkfornitore'].value ===false && f.controls['checkinteroSettore'].value ===false" class="alert mt-3 alert-danger">
                    Deve essere selezionato un campo
                  </div>
                </div>

                <!-- intervento di recuper -->
                <div class="col-12 mt-5">
                  <label class="bolder">Intervento di recupero</label>
                  <div class="position-relative">
                    <textarea name="interventoRecupero" id="" rows="7" cols="44" style="width:100%;" placeholder="Descrizione" ngModel></textarea>
                  </div>
                  <div class="row mt-4">
                    <div class="col-6">
                      <label>Eseguito da:</label>
                      <div class="position-relative">
                        <mat-form-field class="autocomplete" appearance="fill">
                          <mat-label>Nome</mat-label>
                          <mat-select [formControl]="eseguitotoDa">
                            <mat-select-trigger >
                              {{eseguitotoDa.value}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let nome of allUsers" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="col-6">
                      <label>In data:</label>
                      <div class="position-relative">
                        <input type="date" name="dataIntervento"  class="form-control" [ngModel] ="date | date:'yyyy-MM-dd'" ngModel/>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Azione Correttiva -->
                <div class="col-12 mt-5">
                  <label class="bolder">Azione Correttiva</label>
                  <div class="position-relative">
                    <textarea name="azioneCorrettiva" id="" rows="6" cols="33" style="width:100%;" ngModel></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row mt-4">
                    <div class="col-12">
                      <label>Invia comunicazione a:</label>
                      <div class="position-relative">
                        <mat-form-field class="autocomplete" appearance="fill">
                          <mat-label>Nome</mat-label>
                          <mat-select [formControl]="nameLeader" >
                            <mat-select-trigger>
                              {{nameLeader.value}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let nome of leaders" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div *ngIf=" !checkLeader" class="alert mt-3 alert-danger">
                        Deve essere selezionato un campo
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- side 2-->
            <div class="col-4 offset-1">
              <div class="row">
                <div class="col-12">
                  <label for="date" class="bolder">Data da</label>
                  <input  type="datetime-local" name="date" [max]="dateTo" placeholder="Data" class="form-control" [(ngModel)] ="date">
                </div>
                <!-- Data NC to -->
                <div class="col-12 mt-3">
                  <label for="date" class="bolder">Data a</label>
                  <input  type="datetime-local"  name="dateTo" [min]="date" placeholder="Data a" class="form-control" [(ngModel)] ="dateTo" >
                </div>
                <div class="col-12 mt-5">
                  <label>Segnalato da:</label>
                  <div class="row">
                    <div class="position-relative">
                      <input type="checkbox" name="checkcliente" [(ngModel)]="isClientChecked"   (change)="onCheckboxClientChanged()" required="{{!isInternoChecked && !isClientChecked}}"/>
                      <label style="margin-left:10px;" for="horns">Cliente</label>
                      <input *ngIf="isClientChecked" type="text" name="segnalatoDa" placeholder="Nome" class="form-control"  ngModel/>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="position-relative">
                      <input type="checkbox" name="checkinterno" [(ngModel)]="isInternoChecked"   (change)="onCheckboxRileInteChanged()" required="{{!isInternoChecked && !isClientChecked}}"/>
                      <label style="margin-left:10px;" for="horns">Rilevata internamente</label>
                      <mat-form-field *ngIf="isInternoChecked" class="autocomplete mt-2" appearance="fill">
                        <mat-label>Nome</mat-label>
                        <mat-select [formControl]="repotedbyAllUsersform">
                          <mat-select-trigger >
                            {{repotedbyAllUsersform.value}}
                          </mat-select-trigger>
                          <mat-option *ngFor="let nome of allUsers" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>


                  </div>

                  <div *ngIf="f.submitted && f.controls['checkcliente'].value ===false && f.controls['checkinterno'].value ===false" class="alert mt-3 alert-danger">
                    Deve essere selezionato un campo
                  </div>
                </div>
                <div class="col-12 mt-5">
                  <div class="row">
                    <div class="col-12 text-center">
                      <label class="bolder">Costo Intervento</label>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <label>Manodopera:</label>
                    <div class="position-relative">
                      <input type="number" name="manodoperaIntervento" [(ngModel)]="interventionLabourCost" (ngModelChange)="updateResultIntervention()" class="form-control"  />
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Perdità aziendale:</label>
                    <div class="position-relative">
                      <input type="number" name="perditàAziendaleIntervento" [(ngModel)]="interventionCompanyLosses" (ngModelChange)="updateResultIntervention()" class="form-control"  />
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Costo Totale:</label>
                    <div class="position-relative">
                      <input type="number" name="costoTotIntervento"  [value]="totalInterventionCost" readonly class="form-control"  />
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-5">
                  <div class="row">
                    <div class="col-12 text-center">
                      <label class="bolder">Costo Azione Correttiva</label>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Manodopera:</label>
                    <div class="position-relative">
                      <input type="number" name="manodoperaAzCor" [(ngModel)]="correctiveLabourCost" (ngModelChange)="updateResultCorrective()" class="form-control"/>
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Materiale:</label>
                    <div class="position-relative">
                      <input type="number" name="materiale"  [(ngModel)]="correctiveMaterial" (ngModelChange)="updateResultCorrective()" class="form-control"/>
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <label>Costo Totale:</label>
                    <div class="position-relative">
                      <input type="number" name="costoTotAzCor"   [value]="correctiveTotalCost" readonly class="form-control"/>
                      <i class="fa fa-eur suffix opacity-50"></i>
                    </div>
                  </div>

                </div>
              </div>


            </div>
          </div>

        </div>


        <h2 class="title-section mt-4" *ngIf="IsAdmin || IsSuperAdmin">Validazione Responsabile</h2>
        <div class="second-section mt-3" *ngIf="IsAdmin || IsSuperAdmin">

          <div class="row">
            <!-- side 1 -->
            <div class="col-7">
              <!-- RV responsabile -->
              <div class="col-12">
                <label for="horns" class="bolder">Responsabile</label>
                <div class="position-relative">
                  <input type="text" name="RVnomeresponsabile" placeholder="Nome" class="form-control"  ngModel/>
                </div>
              </div>

              <div class="col-12 mt-5">
                <label clsa="m-0">Esito dell'azione Correttiva:</label>
                <div class="row position-relative mt-3">
                  <div class="col-4">
                    <div class="positive d-flex align-items-center">
                      <input type="checkbox" name="RVEsitopositivo"   [(ngModel)]="isPositiveChecked" (change)="onCheckboxRVPositiveChanged()" required="{{!isPositiveChecked && !isNegativeChecked}}"/>
                      <label class="mx-4 mb-0" for="horns">Positivo</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="negative d-flex align-items-center">
                      <input type="checkbox" name="RVEsitonagativo"   [(ngModel)]="isNegativeChecked" (change)="onCheckboxRVNegativeChanged()" required="{{!isPositiveChecked && !isNegativeChecked}}"/>
                      <label class="mx-4 mb-0" for="horns">Negativo</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="f.submitted && f.controls['RVEsitopositivo'].value ===false && f.controls['RVEsitonagativo'].value ===false" class="alert mt-3 alert-danger">
                  Deve essere selezionato un campo
                </div>
              </div>
              <div class="col-12 mt-5">
                <label>Nome di chi ha eseguito la Verifica:</label>

                <div class="position-relative">
                  <mat-form-field class="autocomplete" appearance="fill">
                    <mat-label>Nome</mat-label>
                    <mat-select [formControl]="RVverificataDa">
                      <mat-select-trigger >
                        {{RVverificataDa.value}}
                      </mat-select-trigger>
                      <mat-option *ngFor="let nome of allUsers" [value]="nome.fullName">{{nome.fullName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

            </div>
            <!-- side 2 -->
            <div class="col-4 offset-1">
              <!-- RV da eseguire entro.. -->
              <div class="col-12">
                <label for="horns" class="bolder">Da eseguire entro il</label>
                <div class="position-relative">
                  <input type="date" name="RVlastDate"  class="form-control" [ngModel] ="date | date:'yyyy-MM-dd'" ngModel/>
                </div>
              </div>
              <!-- data verifica -->
              <div class="col-12 mt-5">
                <label for="horns">Data Verifica:</label>
                <div class=" position-relative">
                  <input type="date" name="RVdataVerifica"  class="form-control" [ngModel] ="date | date:'yyyy-MM-dd'" ngModel/>

                </div>
              </div>
              <div class="col-12 mt-5">
                <label>Tipo di Non Conforrmità</label>
                <div class=" position-relative">
                  <div class="row mt-3">
                    <div class="col-6">
                      <div class="position-relative d-flex align-items-center">
                        <input type="checkbox" name="tipoNCgrave" [(ngModel)]="isNCSeriousChcked" (change)="onCheckboxseriousChanged()" required="{{!isNCSeriousChcked && !isNCMinorChecked}}"/>
                        <label class="mx-3 mb-0" for="horns">N.C grave</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="position-relative d-flex align-items-center">
                        <input type="checkbox" name="tipoNCminore" [(ngModel)]="isNCMinorChecked"  (change)="onCheckboxminorChanged()"  required="{{!isNCSeriousChcked && !isNCMinorChecked}}"/>
                        <label class="mx-3 mb-0" for="horns">N.C minore</label>
                      </div>
                    </div>

                    <div *ngIf="f.submitted && f.controls['tipoNCgrave'].value ===false && f.controls['tipoNCminore'].value ===false" class="alert mt-3 alert-danger">
                      Deve essere selezionato un campo
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>


        <h2 class="title-section mt-4" *ngIf="IsSuperAdmin">Validazione R.S.Q.</h2>
        <div class="last-section mt-3" *ngIf="IsSuperAdmin">

          <div class="row">

            <!-- side 1 -->
            <div class="col-7">
              <div class="col-12">
                <label for="horns" class="bolder">Non conformità chiusa?</label>
                <div class="row position-relative mt-3">
                  <div class="col-4">
                    <div class="positive d-flex align-items-center">
                      <input type="checkbox" name="RSQchiusaSI" [(ngModel)]="isNCcloseYes" (change)="onCheckboxRSQisNCcloseYesChanged()" required="{{!isNCcloseYes && !isNCcloseNo}}"/>
                      <label class="mx-4 mb-0" for="horns">Si</label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="negative d-flex align-items-center">
                      <input type="checkbox" name="RSQchiusaNO" [(ngModel)]="isNCcloseNo"  (change)="onCheckboxRSQisNCcloseNoChanged()" required="{{!isNCcloseYes && !isNCcloseNo}}"/>
                      <label class="mx-4 mb-0" for="horns">No</label>
                    </div>
                  </div>
                </div>
                <div *ngIf="f.submitted && f.controls['RSQchiusaSI'].value ===false && f.controls['RSQchiusaNO'].value ===false" class="alert mt-3 alert-danger">
                  Deve essere selezionato un campo
                </div>
              </div>
              <div class="col-12 mt-5">

                <label>Firma:</label>
                <div class="position-relative">
                  <input type="text" name="RSQnomeResponsabile" placeholder="Nome e Cognome" class="form-control" ngModel/>
                </div>
              </div>
            </div>
            <div class="col-4 offset-1">
              <div class="col-12">
                <label>Data chiusura:</label>
                <div class="position-relative">
                  <input type="date" name="RSQdataChiusura" class="form-control" [ngModel] ="date | date:'yyyy-MM-dd'" ngModel/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5 d-flex justify-content-center">
          <button type="submit" class="btn btn-main">Invia</button>
        </div>


      </form>
    </div>
  </div>

</div>

<app-footer></app-footer>
