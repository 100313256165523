<app-header></app-header>
<div class="container heightcontent my-5">
  <div class="row d-flex align-items-center">
    <div class="col-8">
      <h2 class="m-0 welcome" style="    font-size: 25px;">Ciao, {{user}}!</h2>
    </div>

    <div class="col-4 text-end">
      <button  *ngIf="isExcel"  [matMenuTriggerFor]="menu" class="btn-add btn-download "><i class="fa fa-download" style="margin-right: 5px;"></i> Scarica</button>
      <mat-menu #menu="matMenu">
        <button  mat-menu-item (click)="detailExcel(form)">Scarica riepilogo</button>
        <button  mat-menu-item (click)="allNcExcel(form)">Scarica dettaglio</button>
      </mat-menu>
      <a href="/newnc">
        <button  class="btn-add radius-left text-white" (click)="add();">
          <span class="span-add">Nuova</span>
        </button>
        <button  class="btn-add-icon radius-right text-white"><i class="fa fa-plus"></i></button>
      </a>
    </div>
  </div>

  <div class="row mt-5 align-items-center mb-3">
    <div class="col-12 d-flex" [formGroup]="form">
      <mat-form-field appearance="standard" [color]="'primary'" id="labelPeriodo" class="pd-right input-custom-home">

        <mat-label class="label-margin">Periodo</mat-label>
        <mat-date-range-input [max]="maxDate" [rangePicker]="picker" formGroupName="range">
          <input matStartDate  formControlName="start" placeholder="Inizio">
          <input matEndDate formControlName="end" placeholder="Fine">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field class="pd-right input-custom-home" formGroupName="filterTypology">
        <mat-label class="label-margin">Tipologia</mat-label>
        <mat-select formControlName="selectedType">
          <mat-option value="Interna">Interna</mat-option>
          <mat-option value="Esterna">Esterna</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="pd-right input-custom-home" formGroupName="filterRVvalidate">
        <mat-label class="label-margin">Validazione responsabile</mat-label>
        <mat-select formControlName="selectRVvalidate">
          <mat-option value="Si">Si</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="pd-right  input-custom-home" formGroupName="filterRSQ">
        <mat-label class="label-margin">Validazione R.S.Q</mat-label>
        <mat-select formControlName="selectRSQ">
          <mat-option value="Si">Si</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input-custom-home" formGroupName="filterRVcritical">
        <mat-label class="label-margin">Criticità</mat-label>
        <mat-select formControlName="selectRvcritical">
          <mat-option value="Minore">Minore</mat-option>
          <mat-option value="Grave">Grave</mat-option>
        </mat-select>
      </mat-form-field>
      <button (click)="applyDateFilter(form)" class="mat-button btn-main small-button radius"><i class="fa fa-search" style="font-size: 23px;"></i></button>

      <button  *ngIf="isFilterApplied" (click)="resetDateFilter(form)" class="mat-button small-button btn-main radius"><i class="fa fa-times"></i></button>

    </div>

  </div>

  <div class="row m-0 mt-5">
    <div class="col-12 border-gradient p-0 d-flex flex-column align-items-center" *ngIf="dataSource && !spinner">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <!-- Position Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width]="columnWidth" class="bolder labetabel"> Numero NC </th>
          <td mat-cell *matCellDef="let element"  [style.width]="columnWidth"> {{element.id}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="dateNonconformity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width]="columnWidth" class="bolder labetabel"> Data </th>
          <td mat-cell *matCellDef="let element"  [style.width]="columnWidth"> {{element.dateNonconformity | date : 'dd/MM/yyyy'}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="typology">
          <th mat-header-cell *matHeaderCellDef  [style.width]="columnWidth" class="bolder labetabel"> Tipologia</th>
          <td mat-cell *matCellDef="let element"  [style.width]="columnWidth"> {{(element.reportedBy == 0) ? "Interna" : "Esterna" }} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="rvResultCa">
          <th mat-header-cell *matHeaderCellDef  [style.width]="columnWidth" class="bolder labetabel">Validazione responsabile </th>
          <td mat-cell *matCellDef="let element"  [style.width]="columnWidth"> {{ (element.rvResultCa == 0) ? "No" : (element.rvResultCa == 1) ? "Si" : "--"  }} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="rsqResponsibleName" >
          <th mat-header-cell *matHeaderCellDef  [style.width]="columnWidth" class="bolder labetabel"> Validazione R.S.Q </th>
          <td mat-cell *matCellDef="let element"  [style.width]="columnWidth"> {{(element.rsqIsRelevant == 0) ? "No" : "Si"}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="rvTypeNonConformity" >
          <th mat-header-cell *matHeaderCellDef  [style.width]="columnWidth" class="bolder labetabel"> Criticità </th>
          <td mat-cell *matCellDef="let element"  [style.width]="columnWidth"> {{(element.rvTypeNonConformity == 0) ? "Minore" : (element.rvTypeNonConformity == 1) ?  "Grave" : "--"}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="interventionTotalCost">
          <th mat-header-cell *matHeaderCellDef  [style.width]="columnWidth" class="bolder labetabel"> Totale dei costi  </th>
          <td mat-cell *matCellDef="let element"  [style.width]="columnWidth"> {{element.interventionLabourCost + element.interventionCompanyLosses + element.correctiveLabourCost + element.correctiveMaterial}} €</td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef [style.width]="columnWidthedittrash" >  </th>
          <td mat-cell *matCellDef="let element" [style.width]="columnWidthedittrash" ><i (click)="gotoeditnc(element.id)" class="fa fa-edit" id="{{element.id}}"></i></td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef [style.width]="columnWidthedittrash" > </th>
          <td mat-cell *matCellDef="let element" [style.width]="columnWidthedittrash" ><i (click)="deletnonConf($event)" id="{{element.id}}" class="fa fa-trash"></i></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="tr-tabel" mat-row (click)="gotoeditnc(element.id)" *matRowDef="let element; let row; let rowIndex = index; columns: displayedColumns;" [ngClass]="rowIndex % 2 === 0 ? 'table-row-even' : 'table-row-odd'"></tr>
        <!-- Row shown when there is no matching data. -->
        <!-- <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Nessun risultato trovato per "{{input.value}}"</td>
    </tr> -->
      </table>
      <mat-paginator class="mt-4 paginator" [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
    </div>
  </div>

  <mat-spinner *ngIf="spinner"></mat-spinner>


</div>

<app-footer></app-footer>
