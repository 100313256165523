import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { detailNonConfComponenet } from './pages/detailNonConf/detailNonConf.component';
import { AddNewNCComponent } from './components/add-new-nc/add-new-nc.component';
import { EditNCComponent } from './components/edit-nc/edit-nc.component';

const routes: Routes = [

  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'index', component: detailNonConfComponenet },
  { path: 'newnc', component: AddNewNCComponent },
  { path: 'editnc', component: EditNCComponent },




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
