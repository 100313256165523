export interface nonConfDto{

  id: number;
  dateNonconformity: Date;
  typology: string;

  ReportedBy: number;
  reportersName: string;
  reasonReportBy: number;

  descIntervention: string;
  interventionBy: string;
  dateIntervention: Date;
  actionIntervention: string;

  interventionLabourCost : number;
  interventionCompanyLosses: number;
  interventionTotalCost: number;

  correctiveLabourCost: number;
  correctiveMaterial: number;
  correctiveTotalCost: number;

  rvName: string;
  rvCompletedBefore: Date;
  rvResultCa: boolean;
  rvVerifiedDate: Date;
  rvVerifiedByName: string;
  rvTypeNonConformity: boolean

  rsqResponsibleName: string
  rsqClosingDate: Date


  ReasonReportName: string

  ReasonReportDesc: string


}
export class nonConf {
  dateNonconformity!: Date;
  typology!: string;

  ReportedBy!: number;
  reportersName!: string;

  reasonReportBy!: number;

  descIntervention!: string;
  interventionBy!: string;
  dateIntervention!: Date;
  actionIntervention!: string;

  interventionLabourCost!: number;
  interventionCompanyLosses!: number;

  correctiveLabourCost!: number;
  correctiveMaterial!: number;

  rvName!: string;
  rvCompletedBefore!: Date;
  rvResultCa!: boolean;
  rvVerifiedDate!: Date;
  rvVerifiedByName!: string;
  rvTypeNonConformity!: boolean

  rsqResponsibleName!: string
  rsqClosingDate!: Date
  ReasonReportName!: string
  rsqIsRelevant!: number;
  rsqTypeofNc!: number;

  ReasonReportDesc!: string




}


export class insertNonconf {
  Id!: number;
  dateNonconformity!: Date;
  dateNonconformityTo!: Date;
  typology!: string;

  ReportedBy!: number;
  reportersName!: string;

  reasonReportBy!: number;
  ReasonReportName!: string
  ReasonReportDesc!: string

  descIntervention!: string;
  interventionBy!: string;
  dateIntervention!: Date;
  actionIntervention!: string;

  interventionLabourCost!: number;
  interventionCompanyLosses!: number;

  correctiveLabourCost!: number;
  correctiveMaterial!: number;

  rvName!: string;
  rvCompletedBefore!: Date;
  rvResultCa!: number;
  rvVerifiedDate!: Date;
  rvVerifiedByName!: string;
  rvTypeNonConformity!: number;

  rsqResponsibleName!: string;
  rsqIsRelevant!: number;
  rsqClosingDate!: Date;

  emailLeader!: string;

}


export class dataExcel{
  dateFrom!: string
  dateTo!: string
  internalNC!: number;
  externalNC!: number;
  totalCostInternalNC!: number
  totalCostExternalNC!: number;

  id!: number[];
  dateNC!: Date[];
  dateNCTo!: Date[];
  typology!: number[];
  rvResultCa!: number[];
  rsqIsRelevant!: number[];
  rvtypeNonConformity!: number[];
  totalCostNC!: number[];


}
