import { DatePipe, formatDate } from '@angular/common';
import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Form, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { dataExcel, insertNonconf, nonConfDto } from 'src/app/models/nonConfDto';
import { nonconformityService } from 'src/app/services/nonconformity.service';
import {  ChangeDetectorRef } from '@angular/core';
import { Params, Router } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import {  reportedbyClientorInternal, isRvResultCa, isRsqIsRelevant, typeNC } from 'src/app/models/allcheckbox';
@Component({
  selector: 'app-detailNonConf',
  templateUrl: './detailNonConf.component.html',
  styleUrls: ['./detailNonConf.component.css'],
})
export class detailNonConfComponenet implements OnInit  {

  user: string = "";
  showAdd: boolean = false
  showedit: boolean = false
  detailNonConf: nonConfDto[] = [];
  idclicked!: number;
  IsAdmin: boolean = localStorage.getItem("role") == "Admin";
  spinner: boolean = true;
  maxDate : Date =  new Date();
  columnWidth = '150px';
  columnWidthedittrash = '35px'
  /**filtri */
  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  form = new FormGroup({
    range: new FormGroup({ start: new FormControl<Date | null>(null), end: new FormControl<Date | null>(null) }),
    filterTypology: new FormGroup({ selectedType: new FormControl}),
    filterRVvalidate: new FormGroup({selectRVvalidate: new FormControl}),
    filterRSQ: new FormGroup({selectRSQ: new FormControl}),
    filterRVcritical: new FormGroup({selectRvcritical: new FormControl})
  });


  constructor(private nonconfservice: nonconformityService, private router: Router, private datePipe: DatePipe ,public cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getUser()
    this.getnonConf();
  }

  private getUser() {
    var username = localStorage.getItem("username")
    if(username){
      this.user=username
    }else{
      this.user = ""
    }

  }

  add() {
    if(this.showAdd){
      this.showAdd=false
    }else{
      this.showAdd=true;
      this.showedit=false
    }
  }
  edit(e: any){
    this.idclicked = e.srcElement.id;
    if(this.showedit){
      this.showedit=false
    }else{
      this.showedit=true;
      this.showAdd=false;
    }
  }
  gotoeditnc(ncId: number) {
    const queryParams: Params = { id: ncId };

    this.router.navigate(
      ["editnc"],
      {
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  displayedColumns: string[] = ['id', 'dateNonconformity', 'typology', 'rvResultCa', 'rsqResponsibleName', 'rvTypeNonConformity', 'interventionTotalCost', 'edit', 'delete'];
  dataSource : any;

  getnonConf(){

    var role = localStorage.getItem('role');
    this.nonconfservice.getALL(role).subscribe(
      result => {
        this.detailNonConf = result
        this.dataSource = new MatTableDataSource(this.detailNonConf);
        this.dataToExcel = this.detailNonConf
        this.spinner = false
        this.cdr.detectChanges();//paginator
        this.paginator._intl.itemsPerPageLabel = 'Filtro';
        this.dataSource.paginator = this.paginator;//paginator
        this.dataSource.sort = this.sort;//serve per i filtri

      },
      err => {
        console.log('HTTP Error', err)
        if (err.status == 401)
          window.open("/", "_self");
      }
    );
  }
  /**serve per filtri sort */
  ngAfterViewInit() {
    this.dataSource = this.sort;//serve per i filtri
  }

  deletnonConf(e: any){
    e.stopPropagation();
    var id = e.srcElement.id;

    if(window.confirm('Sei sicuro di voler eliminare la Non Conformità?')){
      this.nonconfservice.deleteNonconf(id).subscribe(result => {
        if(result){
          location.reload()
        }else{
          alert('errore')
        }

      });
     }


  }


  /**search
  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }*/

  dataToExcel: any;
  isExcel: boolean = true;
  isFilterApplied: boolean = false;
  /**filtri */
  applyDateFilter(f: FormGroup) {
    this.dataSource = this.detailNonConf;
    var startDate =    this.datePipe.transform(f.value.range.start, 'yyyy-MM-dd');
    var endDate =  this.datePipe.transform(f.value.range.end, 'yyyy-MM-dd');
    if(startDate && endDate){
      this.dataSource = this.detailNonConf.filter((dateNonconformity:any) =>
        dateNonconformity.dateNonconformity >= startDate! && dateNonconformity.dateNonconformity <= endDate!
      );
    }

    /**Filtro per tipologia */
    var type = f.value.filterTypology.selectedType
    if(type == 'Interna'){
      this.dataSource = this.dataSource.filter((type:any) =>
        type.reportedBy === reportedbyClientorInternal.reportedbyInternal
      );
    }else if(type == 'Esterna'){
      this.dataSource = this.dataSource.filter((type:any) =>
      type.reportedBy === reportedbyClientorInternal.reportedbyClient
    );
    }

    /**Filtro per Validazione Responsabile */
    var RVval = f.value.filterRVvalidate.selectRVvalidate
    if(RVval == 'Si'){
      this.dataSource = this.dataSource.filter((rv:any) =>
        rv.rvResultCa === isRvResultCa.rvResultCaPositive
      );
    }else if(RVval == 'No'){
      this.dataSource = this.dataSource.filter((rv:any) =>
        rv.rvResultCa === isRvResultCa.rvResultCaNegative
    );
    }

    /**Filtro per Validazione R.S.Q */
    var RSQval = f.value.filterRSQ.selectRSQ
    if(RSQval == 'Si'){
      this.dataSource = this.dataSource.filter((rsq:any) =>
        rsq.rsqIsRelevant === isRsqIsRelevant.rsqIsRelevantYes
      );
    }else if(RSQval == 'No'){
      this.dataSource = this.dataSource.filter((rsq:any) =>
        rsq.rsqIsRelevant === isRsqIsRelevant.rsqIsRelevantNo
    );
    }

    /**Filtro per criticità grave o minore */
    var criticalNc = f.value.filterRVcritical.selectRvcritical
    if(criticalNc == 'Minore'){
      this.dataSource = this.dataSource.filter((rvCritical:any) =>
      rvCritical.rvTypeNonConformity === typeNC.minore
      );
    }else if(criticalNc == 'Grave'){
      this.dataSource = this.dataSource.filter((rvCritical:any) =>
      rvCritical.rvTypeNonConformity === typeNC.grave
    );
    }

    this.dataToExcel = this.dataSource
    if(this.dataSource.length <= 0){
      this.isExcel= false
    }else{
      this.isExcel= true
    }

    this.dataSource = new MatTableDataSource(this.dataSource);//attivo i filtri ordinamento tabella anche quando filtro per data ...
    this.dataSource.paginator = this.paginator;//paginator
    this.dataSource.sort = this.sort;//serve per i filtri


    this.isFilterApplied = true;

  }

  resetDateFilter(f: FormGroup) {
    f.reset();
    this.getnonConf()
    this.isFilterApplied = false;
  }

  detailExcel(f: FormGroup) {
    let data = new dataExcel()

    var internalNC: any;
    internalNC =   this.dataToExcel.map((id: any)=> id.reportedBy)

    /**toale nc interne*/
    let onlyInternalNC = this.dataToExcel.filter((repBy:any) => repBy.reportedBy === reportedbyClientorInternal.reportedbyInternal);
    let countinternal = onlyInternalNC.length; //totale nc interne
    let getTotalCostInternalNC = onlyInternalNC.map((data: any)=> data.correctiveLabourCost + data.correctiveMaterial + data.interventionCompanyLosses + data.interventionLabourCost)
    let totalCostInternalNC = 0; //costo totale nc interne
    for(let i = 0; i < getTotalCostInternalNC.length; i++) {
      totalCostInternalNC += getTotalCostInternalNC[i];
    }
    /**toale nc interne*/
    let onlyexternalNC = this.dataToExcel.filter((repBy:any) => repBy.reportedBy === reportedbyClientorInternal.reportedbyClient);
    let countexternal = onlyexternalNC.length; //totale nc esterne
    let getTotalCostExternalNC= onlyexternalNC.map((data: any)=> data.correctiveLabourCost + data. correctiveMaterial + data.interventionCompanyLosses + data.interventionLabourCost)
    let totalCostExternalNC = 0; //costo totale nc esterne
    for(let i = 0; i < getTotalCostExternalNC.length; i++) {
      totalCostExternalNC += getTotalCostExternalNC[i];
    }

    /**Data da/a*/
    const format = 'dd/MM/yyyy';
    const locale = 'en-US';
    var datefromTo = false
    var dataDA: any
    var dataA: any
    if(f.value.range.start != null && f.value.range.end != null){
      var datefromTo = true
      dataDA =formatDate(f.value.range.start, format, locale);
      dataA =formatDate(f.value.range.end, format, locale);
    }



    data.dateFrom = dataDA
    data.dateTo = dataA
    data.internalNC = countinternal;
    data.externalNC = countexternal;
    data.totalCostInternalNC = totalCostInternalNC
    data.totalCostExternalNC = totalCostExternalNC

    this.nonconfservice.detailexcel(data).subscribe((data: any) =>{

      if(datefromTo){
        var name = "NC_Riepilogo_"+dataDA+"-"+dataA;
      }else{
        var name = "NC_Riepilogo_ALL"
      }

      this.downloadBase64File(data, name)

     })

  }

  private downloadBase64File(contentBase64: string, fileName: string) {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
  }

  allNcExcel(f: FormGroup) {
    let data = new dataExcel()

    data.id = this.dataToExcel.map((id: any)=> id.id)
    data.dateNC = this.dataToExcel.map((date: any)=> date.dateNonconformity)
    data.dateNCTo = this.dataToExcel.map((date: any)=> date.dateNonconformityTo)
    data.typology = this.dataToExcel.map((repBy: any)=> repBy.reportedBy)
    data.rvResultCa = this.dataToExcel.map((rvresult: any)=> rvresult.rvResultCa)
    data.rsqIsRelevant = this.dataToExcel.map((rsqIs: any)=> rsqIs.rsqIsRelevant)
    data.rvtypeNonConformity = this.dataToExcel.map((rvtyp: any)=> rvtyp.rvTypeNonConformity)
    data.totalCostNC = this.dataToExcel.map((totalcostnc: any)=> totalcostnc.interventionLabourCost + totalcostnc.interventionCompanyLosses + totalcostnc.correctiveLabourCost + totalcostnc.correctiveMaterial)


    /**Data da/a*/
    const format = 'dd/MM/yyyy';
    const locale = 'en-US';
    var datefromTo = false
    if(f.value.range.start != null && f.value.range.end != null){
      datefromTo = true
      var dataDA =formatDate(f.value.range.start, format, locale);
      var dataA =formatDate(f.value.range.end, format, locale);
    }

    this.nonconfservice.allNCexcel(data).subscribe((data: any) =>{
      if(datefromTo){
        var name = "NC_Dettaglio_"+dataDA+"-"+dataA;
      }else{
        var name = "NC_Dettaglio_ALL"
      }

      this.downloadBase64File(data, name)

     })

  }
}
