import { Component, OnInit } from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  firstname = localStorage.getItem("username")
  lastname = localStorage.getItem("lastname")
  role = localStorage.getItem("role")

  constructor() { }

  ngOnInit(): void {
  }


  logOut() {
    localStorage.removeItem("JWT");
    localStorage.removeItem("username");
    window.open("/login", "_self");
  }
}
