import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginDto } from 'src/app/models/LoginDto';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorLogin: boolean = false;
  errorForm: boolean = false;
  showPass: boolean = false;
  constructor(private loginService: LoginService) { }

  ngOnInit(): void {


  }

  onsubmit(f: NgForm){

    var user = new LoginDto;
    user.userName = f.value.username;
    user.password = f.value.password;


    if(f.valid){
      this.loginService.Login(user).subscribe(result =>{
        if(result.error){
          this.errorLogin = true;
        } else {
          localStorage.setItem("JWT", result.token);
          localStorage.setItem('username', result.firstName)
          localStorage.setItem('lastname', result.lastName)
          localStorage.setItem('id', result.id)
          localStorage.setItem('role', result.group)
          window.open('/index', "_self");
          f.reset();
        }
      })
    }else{
      this.errorForm=true;
    }



  }
}
