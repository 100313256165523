
  <div class="footer container-fluid">
    <div class="content-footer">
      <div class="container d-flex align-items-center justify-content-center flex-column">
        <div class="row">
          <p class="m-0">Powered by <a href="https://www.reputationmanager.it/" target="_blank">Reputation Manager</a></p>
        </div>
      </div>
    </div>
  </div>
