export enum reportedbyClientorInternal {
   reportedbyClient = 1,
   reportedbyInternal = 0
}
export enum isFornitoreOrSettoreint{
   reasonRepByFornitore = 1,
   reasonRepBySettoreInt = 0
}

/**admin RV*/
export enum isRvResultCa{
   rvResultCaPositive = 1,
   rvResultCaNegative = 0
}
export enum typeNC{
   grave = 1,
   minore = 0
}
/**superAdmin RSQ */
export enum isRsqIsRelevant{
   rsqIsRelevantYes = 1,
   rsqIsRelevantNo = 0
}
